.bloc-contact
  .contact-image
    margin-bottom: spacer(4)
    height: 200px
    object-fit: cover
  .social-network
    margin-top: spacer(4)
    i
      @include themify($schemes)
        color: schemed('primary')
        background-color: schemed('secondary')
        border-color: schemed('secondary')

@media #{$breakpoint-larger-than-sm-768px}
  .bloc-contact
    .contact-image
      height: 400px
