.bloc-mailing-list
  .mailing-list-error, .mailing-list-ok
    padding: spacer(2)
    margin-bottom: spacer(2)
    @include themify($schemes)
      color: schemed('primary')
      background-color: schemed('secondary')
    .error-message + .error-message
      margin-top: spacer(1)

  // More compact style when embedded e.g. in footer
  &.bloc-mailing-list-embedded
    input, button
      padding: spacer(1) spacer(2)
