.polaroid
  padding: spacer(4)
  @include themify($schemes)
    color: schemed('primary')
    background-color: schemed('secondary')

  flex: 1

  h3, h4, p
    margin-bottom: 0
    line-height: 140%

  p
    @include themify($schemes)
      color: schemed('primary')
    font-weight: normal
    em
      @include themify($schemes)
        color: $noir

  .link
    @include themify($schemes)
      color: schemed('primary')
    &:hover
      @include themify($schemes)
        color: schemed('primary-hover')

  img
    margin-bottom: spacer(2)
    height: unset

// TEMOIGNAGE
.horizontal-polaroid
  display: flex
  align-items: center
  padding: spacer(1)
  margin-bottom: spacer(2)

  img
    width: auto
    height: spacer(9)
    margin-bottom: 0
    margin-right: spacer(1)

// PORTRAIT CHIFFRE
.polaroid-content-portrait-chiffre
  display: flex
  align-items: center
  .number
    margin-right: spacer(1)

// PORTRAIT NOM
.polaroid-content-portrait-nom
  .lien
    margin-top: spacer(2)


@media #{$breakpoint-larger-than-sm-768px}
  // PORTRAIT NOM
  .polaroid-content-portrait-nom
    .lien
      margin-top: 0
