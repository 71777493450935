.bloc-date-et-lieu-evenement
  padding-bottom: 0
  .date-et-lieu-evenement
    padding-top: spacer(2)
    display: flex
    flex-direction: column
    &> .date-evenement, .lieu-evenement
      margin-bottom: spacer(4)
      display: flex
      align-items: center
      @include themify($schemes)
        color: schemed('secondary')
        background-color: schemed('primary')
    span
      padding: spacer(1) spacer(1)
      @include themify($schemes)
        color: schemed('primary')
        background-color: schemed('secondary')
    .date-evenement
      .ph-calendar
        font-size: 2.9em
        margin-right: spacer(3)
        display: block
      .h5-size
        margin: 0
    .lieu-evenement
      .ph-map-pin
        font-size: 2.9em
        margin-right: 1.55rem
        display: block
      .h5-size
        margin: 0

@media #{$breakpoint-larger-than-sm-768px}
  .bloc-date-et-lieu-evenement
    .date-et-lieu-evenement
      padding-top: 0
      flex-direction: row
      justify-content: space-between
      span
        padding: spacer(1) spacer(2)
      .date-evenement
        display: block
        .ph-calendar
          font-size: 4em
          margin: 4px spacer(3)
          display: block
      .lieu-evenement
        display: flex
        flex-direction: column
        align-items: flex-end
        .ph-map-pin
          font-size: 4em
          margin: 4px spacer(3)
          display: block
