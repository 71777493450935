.fancy-selector
  position: relative

  .fancy-selector-trigger
    @include themify($schemes)
      color: schemed('primary')
      background-color: schemed('secondary')

    @extend .btn-primary
    display: flex

  .fancy-selector-content
    position: absolute
    left: 0
    right: 0
    transition: 5s
    padding: spacer(2)
    border-radius: 0 0 4px 4px
    border: 1px solid
    @include themify($schemes)
      border-color: schemed('primary')
      background-color: schemed('secondary')
    ul
      li
        margin-bottom: 0
        margin-left: spacer(3)
        a
          display: block
          padding: spacer(1) 0
          @include themify($schemes)
            color: schemed('primary')
          &:hover
            color: $jaune-dark
