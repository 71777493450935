.footer
  @include themify($schemes)
    background-color: schemed('primary')
  .display-block-emphasize-xs
    em
      display: block

  .social-network
    text-align: center
    ul
      justify-content: center
      padding-top: 0
      i
        border-style: solid
        border-radius: 50%
        @include themify($schemes)
          color: $light-color
          background-color: $noir
          border-color: $noir

  .bloc.navigation
    padding-top: 0
    h3
      color: $vert
    label
      color: $rouge

  .navigation
    h2.menu-item, h3.menu-item
      margin: spacer(2) 0
      &:after
        margin-left: 10px
    .subnavigation-items
      margin: spacer(2) 0
      @include unstyled-links
    .newsletter
      margin: spacer(3) 0

  .secondary-navigation
    ul
      display: block
      li
        margin-right: 0
        &:last-child
          margin-right: 0

  .info-footer
    .info-footer-items
      display: flex
      flex-direction: column
      align-items: center
      text-align: center
      img
        height: spacer(7)
        width: auto
      .avis-verifies a
        width: auto
      h3
        font-size: $base-size
      > *
        margin-top: spacer(5)
        margin-bottom: 0
      .widgets
        display: flex
        > * + *
          margin-left: spacer(4)

@media #{$breakpoint-larger-than-sm-768px}
  .footer
    .display-block-emphasize-xs
      em
        display: inline
    .social-network
      text-align: center
      ul
        padding-top: spacer(4)
      li
        font-size: 4em
    .bloc.navigation
      padding-top: spacer(5)
    .navigation
      h2.menu-item, h3.menu-item
        display: block
        margin-top: 0
        &:after
          content: unset
      .subnavigation-items
        margin: 0
        @include visible
      .newsletter
        margin-top: 0
    .secondary-navigation
      ul
        display: flex
        text-align: left
        li
          margin-right: spacer(6)
      .subnavigation-items a:hover
        color: $vert
    .info-footer
      .info-footer-items
        display: flex
        flex-direction: row
        align-items: center
        > *
          margin-top: 0
          margin-right: spacer(4)
        img
          height: spacer(9)
        .copyright
          margin-left: auto
    .accordion-button
      cursor: default

@media #{$breakpoint-larger-than-md-992px}
  .footer
    .bloc.navigation
      padding-top: spacer(9)
