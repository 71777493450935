.bloc-documents
  i
    font-size: 1.5rem
  a
    @include themify($schemes)
      color: schemed('secondary')

  hr
    @include themify($schemes)
      border-color: schemed('secondary')
    padding-bottom: spacer(2)
    margin-top: spacer(2)
  .document-title
    margin-bottom: spacer(2)

@media #{$breakpoint-larger-than-md-992px}
  .bloc-documents
    .document-title
      margin-bottom: 0
