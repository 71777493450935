$bloc-vertical-padding: spacer(5)
$bloc-vertical-padding-larger-than-md-992px: spacer(9)

.bloc
  padding: $bloc-vertical-padding 0
  @include themify($schemes)
    background-color: schemed('primary')
    color: schemed('secondary')
  p, ul, ol
    @include themify($schemes)
      color: schemed('negative')
    a
      text-decoration: underline
      @include themify($schemes)
        color: schemed('paragraph-link')
    em
      color: $noir

  img
    @include prismic-image

    &.ignore-height
      height: unset

  .last-item-margin-top
    margin-top: spacer(4)

  ul, ol, div[data-oembed]
    margin-bottom: spacer(4)

main.page div:last-of-type[class*="negatif"] .bloc
  background-color: $blanc

@media #{$breakpoint-larger-than-md-992px}
  .bloc
    padding: $bloc-vertical-padding-larger-than-md-992px 0
