.bloc-contenu-libre
  .block-img
    text-align: center
    img
      width: auto
      height: auto
      max-width: 100%
  div[data-oembed]
    /* Prismic embedded video container that contains an iframe */
    display: flex
    justify-content: center
  // Copied from https://avexdesigns.com/blog/responsive-youtube-embed
  div [data-oembed-provider=youtube]
    position: relative
    height: 0
    overflow: hidden
    iframe
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%

  // <h*> sizes was considered too big on blog articles (#5377), we reduce it this way
  h1
    @extend .h2-size
  h2
    @extend .h3-size
  h3
    @extend .h4-size
  h4
    @extend .h5-size
  h5
    @extend .h6-size
