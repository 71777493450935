.social-network
  ul
    display: flex
    li
      i
        border-width: 0.12em
        border-style: solid
        border-radius: 50%
        font-size: 0.8em
      font-size: 2.7em
      margin-right: spacer(4)
      &:last-child
        margin-right: 0
      @include unstyled-links
