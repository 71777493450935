.js-accordion-trigger
  justify-content: space-between
  &:after
    font-family: $icon-font
    content: $ph-caret-down-bold
    align-self: center
    vertical-align: middle

.js-accordion-container.closed
  .js-accordion-trigger
    &:after
      transform: rotate(0deg)
      @include transition

.js-accordion-container:not(.closed)
  .js-accordion-trigger
    &:after
      transform: rotate(180deg)
      @include transition
