@mixin responsive-padding
  padding: spacer(2) spacer(3)
  @media #{$breakpoint-larger-than-sm-768px}
    padding: 20px spacer(3)

$button-margin-top: spacer(4)

.btn-primary
  @include responsive-padding

  &.hidden-unless-xs, &.hidden-unless-sm
    margin-top: $button-margin-top

.btn-primary.btn-small
  @include btn-small

.btn-primary-jaune
  @extend .btn-primary
  background-color: $jaune
  border: 1px solid $jaune
  &:hover
    background-color: $jaune-dark
    border: 1px solid $jaune-dark

.btn-primary-outline
  @extend .btn-primary
  background-color: transparent
  text-align: center
  display: block

  &.btn-small
    padding: spacer(1) .7rem

  &-vert
    @extend .btn-primary-outline
    color: $vert
    border: 2px solid $vert
    &:hover
      background-color: transparent
      border: 2px solid $vert-dark

  &-rouge
    @extend .btn-primary-outline
    color: $rouge
    border: 2px solid $rouge
    &:hover
      background-color: transparent
      border: 2px solid $rouge-dark

.btn-group
  display: flex
  flex-direction: column
  width: fit-content
  text-align: left
  margin-top: spacer(2)
  .btn
    margin-top: spacer(2)

@media #{$breakpoint-larger-than-sm-768px}
  .btn-group
    margin-top: spacer(0)
    flex-direction: column-reverse

.button-bottom
  margin-top: $button-margin-top

.bloc .btn-primary
  @include themify($schemes)
    color: schemed('primary')
    background-color: schemed('secondary')
    border: 1px solid schemed('secondary')

  &:hover
    @include themify($schemes)
      background-color: schemed('secondary-hover')
      border: 1px solid schemed('secondary-hover')

.bloc .link
  @include themify($schemes)
    color: schemed('secondary')

  &:hover
    @include themify($schemes)
      color: schemed('secondary-hover')
