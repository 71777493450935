.pagination-blog
  .element
    padding-bottom: spacer(11)
  .pagination
    margin-top: - spacer(9)
    &.no-results
      margin-top: 0
    .page
      @include themify($schemes)
        color: schemed('negative')
      a:hover
        text-decoration: underline
    .page + .page:before
      content: " - "
    .page + .gap:before
      content: ""
    .gap + .page:before
      content: ""
    .current
      @include themify($schemes)
        color: schemed('secondary')


