.bloc .vignette
  padding: spacer(4)
  @include themify($schemes)
    color: schemed('primary')
    background-color: schemed('secondary')

  position: relative
  flex: 1
  display: flex
  flex-direction: column

  &-maxi-padding
    padding: spacer(6)

  &.past-event
    opacity: 0.6

  h2, h3
    margin-bottom: spacer(1)

  .emphasized-text
    color: $noir
    em
      @include themify($schemes)
        color: schemed('primary')

  p
    @include themify($schemes)
      color: schemed('vignette-text')
    padding-bottom: spacer(1)
    a
      @include themify($schemes)
        color: schemed('vignette-text-link')

  .link
    @include themify($schemes)
      color: schemed('primary')
    &:hover
      @include themify($schemes)
        color: schemed('primary-hover')

  .texte-vignette
    flex-grow: 1

.bloc-carrousel-vignette-chiffre
  .sur-titre
    p
      margin-bottom: spacer(2)

.bloc-vignette-texte-5-colonnes
  .sur-titre
    p
      margin-bottom: spacer(2)
  .bottom-row
    margin-top: spacer(2)
