.bloc-couverture-page-image
  .couverture-content
    padding: spacer(7) 0
    h1
      line-height: 90%
      span
        display: block
      &.blanc
        color: $light-color
  .colored-background
    @include themify($schemes)
      background-color: schemed('primary')

@media #{$breakpoint-larger-than-sm-768px}
  .bloc-couverture-page-image
    .couverture-content
      padding: spacer(11) 0

@media #{$breakpoint-larger-than-xl-1600px}
  .bloc-couverture-page-image
    .couverture-content
      padding: spacer(12) 0
