.color-reverse
  .glide__arrow
    @include themify($schemes)
      color: schemed('secondary')
      background-color: schemed('primary')

.glide__arrow
  [class^=ph-]
    font-size: 2em
  @include themify($schemes)
    color: schemed('primary')
    background-color: schemed('secondary')
  padding: spacer(2)
  box-shadow: none
  border-width: 0
  border-radius: 0

.glide__arrow--left
  left: 0

.glide__arrow--right
  right: 0

.glide__arrows--top
  .glide__arrow
    top: 0

.slides-column
  .glide__slide
    flex-flow: column nowrap

.same-height
  height: auto

.glide--swipeable-disabled
  cursor: auto  // don't promote grabbing if the slider is disabled

.glide__slide
  display: flex
  padding-top: 5px // prevent accents from being truncated
  img
    @include prismic-image

    &.ignore-height
      height: unset
