.edito-cover
  background-position-x: center
  background-position-y: bottom
  height: 300px

.bloc-edito
  img, .video-container
    margin-top: $button-margin-top
  .edito-row:not(:first-of-type)
    padding-top: $bloc-vertical-padding
  .edito-cover
    height: 100%
    object-fit: cover
  img
    height: unset

@media #{$breakpoint-larger-than-md-992px}
  .edito-cover
    height: 500px
  .bloc-edito
    img, .video-container
      margin-top: 0

    .texte-2-col
      column-count: 2
      column-gap: spacer(4)
    .edito-row:not(:first-of-type)
      padding-top: $bloc-vertical-padding-larger-than-md-992px
