.formulaire-contact
  hr
    margin-top: spacer(2)
    margin-bottom: spacer(2)
    &:last-of-type
      margin-bottom: spacer(4)
  .question
    a
      @include themify($schemes)
        color: schemed('secondary')
  label.corpo-select
    box-sizing: border-box
    display: flex
    width: 100%
    margin-bottom: spacer(2)
  .label-for-select
    display: block
    margin-bottom: spacer(1)
  input, textarea
    @include responsive-padding
    font-family: $title-font
    @include themify($schemes)
      background-color: schemed('primary')
      color: schemed('secondary')
      border: 4px solid schemed('secondary')
    margin-bottom: spacer(2)
  input[type=text], input[type=tel], input[type=email], input[type=number], textarea
    margin-top: spacer(1)
    display: block
    width: 100%
    box-sizing: border-box
  textarea
    padding: spacer(3)
  input[type=checkbox]
    margin-right: spacer(1)
  .confirmation-message
    color: $vert !important
  .error-message
    color: $rouge !important
  input.field-with-error, textarea.field-with-error
    border-color: $rouge !important
  select.field-with-error
    color: $rouge !important

  .contact-instructions
    p
      margin-bottom: 0
      @include themify($schemes)
        color: schemed('secondary')
    address
      @extend h4
      @extend .h4-size
      font-style: normal // address tag is italic by default
      a
        @include themify($schemes)
          color: schemed('negative')
  .enercoop-ethics-container
    hr
      margin-top: spacer(4)
      margin-bottom: spacer(4)
    li
      @include themify($schemes)
        color: schemed('secondary')

@media #{$breakpoint-larger-than-sm-768px}
  .formulaire-contact
    label.corpo-select
      width: auto
