.bloc-couverture-page-accueil
  .cover
    height: 350px

  .container-fluid
    position: relative
    padding-bottom: 72px
    margin: 0 spacer(2)

  h1
    position: absolute
    bottom: 0
    left: 0
    font-size: 45px

  .emphasized-text
    em
      color: $light-color
    .bg-vert
      background-color: $vert
      color: $light-color
      display: inline-block
      padding: 0 5px

.container-hr
  padding: 60px 0 0

@media #{$breakpoint-larger-than-sm-768px}
  .bloc-couverture-page-accueil
    .container-fluid
      padding-bottom: 112px

    h1
      font-size: 70px

    .btn
      position: absolute
      bottom: 8px
      right: 0

@media #{$breakpoint-larger-than-md-992px}
  .bloc-couverture-page-accueil
    .cover
      height: 600px
    .container-fluid
      padding-bottom: 144px
      margin: 0 spacer(4)
    h1
      font-size: 90px
    .btn
      bottom: 12px

@media #{$breakpoint-larger-than-lg-1200px}
  .bloc-couverture-page-accueil
    .container-fluid
      margin: 0 $container-fluid-spacing-lg

@media #{$breakpoint-larger-than-xl-1600px}
  .bloc-couverture-page-accueil
    .container-fluid
      margin: 0 auto
    h1
      // Use the padding of a container-fluid, since the parent's one is not used
      @include container-fluid-padding-lg
    .btn
      right: $container-fluid-spacing-lg
