$navigation-height-mobile: spacer(8)
$navigation-height-desktop: spacer(10)

$navigation-mainbar-padding-mobile: spacer(2)
$navigation-mainbar-padding-desktop: spacer(4)

$navigation-logo-width-mobile: 6rem
$navigation-logo-width-laptop: 7.8rem  // This number is to avoid navbar button to split in two lines before breakpoint
$navigation-logo-width-desktop: 7.8rem  // menu got crowded..we used to have a bigger logo at 11.8rem

$navigation-margin-items: spacer(3)

$navigation-desktop-button-width: spacer(10)
$navigation-desktop-margin-between-buttons: spacer(2)
$navigation-desktop-button-horizontal-padding: 0.25rem
$navigation-desktop-button-vertical-padding: spacer(1)
$navigation-button-border: 2px

$min-laptop-navigation-width: 1163px
$min-desktop-navigation-width: 1355px

$breakpoint-larger-than-laptop-navigation: "only screen and (min-width: #{$min-laptop-navigation-width})"
$breakpoint-smaller-than-navigation-breakpoint: "only screen and (max-width: #{$min-laptop-navigation-width - 1})"

$breakpoint-larger-than-desktop-navigation: "only screen and (min-width: #{$min-desktop-navigation-width})"

$bootstrap-col-margin: 1rem

@mixin green-when-hover
  transition: color .15s
  &:hover
    color: $vert
  &.active
    color: $vert

@mixin white-background
  color: $noir
  .logo
    @include visible
  .logo-blanc
    @include hidden
  .navigation-burger-menu
    color: $noir
  .navigation-item-menus-wrapper
    opacity: 1
  .navigation-items
    .navigation-item
      color: $noir
      @include green-when-hover

.nav-wrapper
  position: fixed
  width: 100%
  z-index: 4
  top: 0

.main-navbar
  display: flex
  flex-direction: row
  justify-content: center
  position: relative
  .main-navbar-inner
    display: flex
    align-items: center
    height: $navigation-height-mobile
    width: 100%

  &.navbar-standard
    @include white-background
    width: 100%
  &.navbar-blog
    background-color: $bleu
    .navigation-item-menus-wrapper
      opacity: 1
    .navigation-item-menus-cover
      background-color: $bleu
      border-bottom-color: $bleu
    .navigation-items
      .navigation-item
        color: $blanc
        transition: color .5s
        &:hover
          color: $jaune
        &.active
          color: $jaune
    .navigation-burger-menu
      color: $blanc
  &.navbar-transparent
    .navigation-items
      .navigation-item
        color: $blanc
    transition: background .5s, color .5s
    .navigation-buttons
      &.icons-mobile
        @include transition
        .masked
          opacity: 0
          visibility: hidden
  .logo img, .logo-blanc img, .logo-blog img
    width: $navigation-logo-width-mobile
    height: 100%
    display: inherit
  .logo
    display: none

  .navigation-items
    display: flex
    align-items: center
    flex-grow: 1
    justify-content: flex-end
    span.navigation-item
      cursor: default

    ul
      display: flex
      margin: 0 $navigation-margin-items
      font-size: 14px
      font-weight: bold
      li
        margin-right: spacer(2)
        margin-bottom: 0
        &:last-child
          margin-right: 0

  .navigation-buttons
    &.icons-mobile
      .btn.btn-nav
        margin-right: spacer(2)
      .btn
        .icons-mobile a
          font-size: 1.6em

.navigation-item-menus-wrapper
  width: 100%
  height: 100%
  position: absolute
  z-index: -1

.navigation-item-menus-cover
  z-index: 1
  background: $blanc
  height: 100%
  width: 100%
  position: absolute
  border-bottom: 1px solid #EBEBEB

.navigation-item-menu
  background-color: $blanc
  font-size: 14px
  width: 100%
  border-top: 1px solid #EBEBEB
  padding-top: spacer(4)
  position: absolute
  bottom: 0
  &.active
    transition: bottom 0.4s ease
  ul
    margin-bottom: spacer(5)
    li a
      @include green-when-hover
  .navigation-item-menu-lien-gauche, .navigation-item-menu-lien-milieu
    font-weight: normal
  .navigation-item-menu-lien-droite
    color: $gris-fonce
  .navigation-item-menu-content
    padding-left: $navigation-margin-items + $navigation-logo-width-laptop


.navigation-container-fluid
  padding-right: spacer(2)
  padding-left: spacer(2)
  max-width: $container-max-width

.navigation-item-menu-mon-espace
  background-color: transparent
  width: auto
  border-top: none
  background: $blanc
  padding: spacer(1) spacer(2)
  border-radius: 0 0 4px 4px
  display: flex
  flex-direction: column
  opacity: 0
  z-index: -1
  margin-left: -1rem
  &.active
    opacity: 1
  .row-navigation
    margin-right: 2 * $navigation-button-border

  .btn.btn-nav
    display: block
    &:before
      content: unset

.navigation-mobile
  position: fixed
  overflow: auto
  left: 0
  top: 0
  height: 100%
  width: 100%
  background-color: $blanc
  transition: all .5s ease
  z-index: 4

  .navigation-mobile-content
    position: relative
    top: spacer(2)
    font-size: 18px
    display: flex
    flex-direction: column
    justify-content: space-between
    margin: 0 spacer(2)
    min-height: 100%
    .navigation-item-mobile
      display: flex
      justify-content: space-between
      padding-bottom: spacer(2)
      &.navigation-item-submenu:after
        font-family: $icon-font
        content: $ph-caret-right-bold
        vertical-align: middle
    .navigation-mobile-titre-menu
      padding-bottom: spacer(2)
    .navigation-link-mobile
      font-size: 16px
      li
        padding-bottom: spacer(2)
      &:last-of-type
        padding-bottom: spacer(6)
    .navigation-link-mobile-gauche, .navigation-link-mobile-milieu
      font-weight: normal
      padding-bottom: spacer(2)
    .navigation-link-mobile-droite
      padding-top: spacer(2)
      a
        color: $gris-fonce
    .navigation-close-menu
      display: flex
      justify-content: space-between
      padding-bottom: spacer(6)
      &:after
        color: $noir
        font-family: $icon-font
        content: $ph-x-bold
        vertical-align: middle
      .invisible-ph-caret-right-bold
        color: $light-color

  .btn-navigation-mobile
    text-align: center
    display: block
    padding: spacer(2)
    margin-top: spacer(4)
    &.btn-subscription:before
      content: unset
    &.btn-espace-client, &.btn-espace-societaire
      text-align: left
      padding: 0

  &:not(.active)
    left: 100%

.anchor::before
  // This code is based upon: https://css-tricks.com/hash-tag-links-padding/
  display: block
  content: " "
  margin-top: -$navigation-height-mobile
  height: $navigation-height-mobile
  visibility: hidden
  pointer-events: none

main > div:first-child
  // Padding above first bloc for nav bar
  padding-top: $navigation-height-mobile
  &.bloc_couverture_page_accueil
    // Except on the home page because the home page navbar is transparent
    padding-top: 0

.alert-banner-wrapper
  width: 100%
  background-color: $light-color
  display: flex
  justify-content: center

.alert-banner
  padding: spacer(2)
  strong
    font-family: $title-font
  color: $vert
  background-color: $light-color
  a
    text-decoration: underline
    color: $vert
.alert-banner--desktop
  display: none

.alert-banner--mobile
  margin-top: spacer(4)
  margin-left: - spacer(2)
  margin-right: - spacer(2)

@media #{$breakpoint-smaller-than-navigation-breakpoint}
  .navbar-transparent
    @include white-background
  .hidden-navigation
    @include hidden

@media #{$breakpoint-larger-than-laptop-navigation}
  .navigation-item-menus-wrapper
    opacity: 0
    transition: opacity 0.5s
  .hidden-unless-navigation
    @include hidden
  .main-navbar
    .navigation-items
      justify-content: space-between
    .main-navbar-inner
      height: $navigation-height-desktop
    .logo img, .logo-blanc img, .logo-blog img
      width: $navigation-logo-width-laptop

  .navbar-transparent
    .logo
      @include hidden
    .logo-blanc
      @include visible
    background-color: unset
    // white-backgrounded on hover
    &.menu-displayed
      @include white-background
    // white-backgrounded when the page is scrolled
    &.scrolled
      @include white-background

  .anchor::before
    margin-top: -$navigation-height-desktop
    height: $navigation-height-desktop

  main > div:first-child
    // Padding above first bloc for nav bar
    padding-top: $navigation-height-desktop

  .main-navbar.navbar-transparent
    &:hover
      @include white-background

  .alert-banner--desktop
    display: block
    width: 100%

@media #{$breakpoint-larger-than-desktop-navigation}
  .main-navbar
    .logo img, .logo-blanc img, .logo-blog img
      width: $navigation-logo-width-desktop
    .navigation-items
      ul
        li
          @media #{$breakpoint-larger-than-desktop-navigation}
            margin-right: spacer(3)
  .navigation-item-menu
    .navigation-item-menu-content
      padding-left: $navigation-margin-items + $navigation-logo-width-desktop - $bootstrap-col-margin
      max-width: $container-max-width
      margin: auto
  .navigation-container-fluid
    padding-left: spacer(9)
    padding-right: spacer(9)
    margin: auto
