body
  font-weight: 500

p
  margin: 0 0 spacer(4) 0
  letter-spacing: 0.05em
  line-height: 200%

// Lists
ul, ol
  margin: 0
  padding: 0
  line-height: normal

  li
    margin-bottom: spacer(2)
    &:last-child
      margin-bottom: 0

ul
  list-style: none
ol
  list-style-position: inside

.secondary-navigation
  font-weight: normal

.secondary-color
  @include themify($schemes)
    color: schemed('secondary')

.rich-text
  *:last-child
    margin-bottom: 0
  em
    font-style: normal
    @include themify($schemes)
      color: schemed('negative-inverse')

  strong
    font-weight: 900
  ul
    li
      display: table
      &::before
        content: "■"
        font-size: spacer(1)
        display: table-cell
        padding-right: spacer(1)
        @include themify($schemes)
          color: schemed('secondary')
  ul, ol
    line-height: 200%
    li
      margin-bottom: 1rem

.blockquote
  &::before
    content: "\201C"
    float: left
    margin-right: 0.25rem
    @include themify($schemes)
      color: schemed('negative')
  span::after
    margin-left: 0.25rem
    content: "\201D"
    @include themify($schemes)
      color: schemed('negative')

  &.blockquote-big
    margin: spacer(9) 0
    position: relative

    &::before
      position: absolute
      left: 0
      top: - spacer(4)
    span::after
      position: absolute
      right: 0
      bottom: - spacer(6)

@mixin unstyled-links
  a
    text-decoration: none
    @include themify($schemes)
      color: schemed('negative')

.emphasized-text
  em
    font-style: normal
    @include themify($schemes)
      color: schemed('secondary')
