.video-container
  overflow: hidden
  position: relative
  width: 100%

.video-container::after
  padding-top: calc(9 / 16) * 100% // Video is in 16/9 format
  display: block
  content: ''

.video-container iframe
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
