.missing-content-alert
  position: fixed
  bottom: 0
  left: 0
  right: 0
  z-index: 10
  padding: 0.2em
  background-color: #FF0000
  color: #ffffff
  font-family: "Impact", sans-serif
  text-align: center
  text-transform: uppercase

.missing-content-bloc, .missing-content-bloc .main-navbar
  border: .25em solid
  border-image: repeating-linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red) 10
