// This custom select needs to be wrapped in a label to work.
// It is based on https://codepen.io/sales/pen/KyXVrw
// The principle is to have a wrapper around the <select> element
// and set the background and border on the wrapper, and remove
// all styling from the <select>.
// We then use the :after feature to add the arrow, which is actually
// below the <select> in terms of z-index, to allow the click to work.
label.corpo-select
  position: relative
  @include themify($schemes)
    background-color: schemed('primary')
    border: 4px solid schemed('secondary')
  z-index: 1

  select
    font-size: 12px
    @include responsive-padding
    text-transform: uppercase
    font-family: $title-font
    @include themify($schemes)
      color: schemed('secondary')
    border: none
    background: transparent
    appearance: none
    margin: 0
    width: 100%
    padding-right: 50px

    &:focus
      outline: none

    option
      @include themify($schemes)
        color: schemed('secondary')
        background-color: schemed('primary')
      &[disabled=disabled]
        display: none

  &:after
    position: absolute
    right: 0
    top: 0
    z-index: -1
    @include themify($schemes)
      color: schemed('secondary')
    padding: 18px
    font-family: $icon-font
    content: $ph-caret-down-bold

@media #{$breakpoint-larger-than-sm-768px}
  label.corpo-select
    select
      padding-right: 60px
      font-size: $button-font-size
    &:after
      padding: 26px
