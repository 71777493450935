.bloc
  &.actualite-ou-evenement
    padding-bottom: 0
    .document-cooperative
      font-family: $base-font
      font-weight: normal
      text-transform: uppercase
      margin-bottom: spacer(3)
    .document-publication-date
      margin-bottom: spacer(3)
    .document-image
      padding-bottom: spacer(4)
      @include themify($schemes)
        background: linear-gradient(to bottom, schemed('primary') 50%, schemed('secondary') 50%)
    .document-lower-part
      @include themify($schemes)
        background-color: schemed('secondary')
      padding-bottom: spacer(1)
      .share-text
        @include themify($schemes)
          color: schemed('vignette-text')
      .social-network
        ul
          padding-top: spacer(2)
          justify-content: center
        i
          @include themify($schemes)
            color: schemed('secondary')
            background-color: schemed('primary')
            border-color: schemed('primary')
