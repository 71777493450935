.filtre-recherche
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: spacer(5)

  > *:not(:last-child)
    margin-bottom: spacer(2)

  label.corpo-select
    width: 100%
    box-sizing: border-box
  .cooperative-button
    align-self: flex-start

@media #{$breakpoint-larger-than-sm-768px}
  .filtre-recherche
    flex-direction: row

    > *:not(:last-child)
      margin-bottom: 0
      margin-right: spacer(3)

    label.corpo-select
      width: auto
